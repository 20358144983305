
.table-declarative th {
    font-size: 0.8rem;
    font-family: arial;
    font-weight: normal;
    text-align: center;
}

.table-declarative tr {
    font-size: 0.9rem;
}

.skill-icon {
    width: 1.1rem;
    height: 1.1em;
}
