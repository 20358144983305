
.table-language th {
    font-size: 0.8rem;
    font-family: arial;
    font-weight: normal;
    text-align: center;
}

.table-language td {
    font-size: 0.9rem;
}

.table-language .table-header {
    display: flex;
}

.table-language .table-header > div {
    flex: 1;
    display: flex;
    justify-content: space-between;
}

.table-language .table-header > div > div {
    cursor: pointer;
}

.table-language .table-header > button {
    margin-left: 1rem;
    visibility: hidden;
}

.table-language td:last-child > div {
    display: flex;
    justify-content: space-between;
}

.skill-icon {
    width: 1rem;
    height: 1em;
}

