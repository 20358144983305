
/* search field */
.search-field {
    background-image: url('../../assets/images/search.svg');
    background-position: 5px 8px;
    background-repeat: no-repeat;
    background-size: 20px;
    padding-left: 30px !important;
    margin-right: 4px;
    min-width: 10rem;
    transition: width .4s ease-in-out;
    font-size: x-small;
}
