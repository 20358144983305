
.table-normalized th {
    font-size: 0.8rem;
    font-family: arial;
    font-weight: normal;
    text-align: center;
}

.table-normalized td {
    font-size: 0.9rem;
}

.table-normalized th:first-child {
    width: 25%;
}

.table-normalized .table-cell {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;    
    align-items: end;
}

.skill-icon {
    width: 1rem;
    height: 1em;
}




